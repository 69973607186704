import { render, staticRenderFns } from "./EmailEditor.vue?vue&type=template&id=3edd567e&scoped=true"
import script from "./EmailEditor.vue?vue&type=script&lang=js"
export * from "./EmailEditor.vue?vue&type=script&lang=js"
import style0 from "@lazy/_editor.scss?vue&type=style&index=0&id=3edd567e&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3edd567e",
  null
  
)

export default component.exports